<template>
  <div>
    <index-filter :cols="[3,3]">
      <search-filter
        slot="col_0"
        v-model="filters"
      />
      <distributor-id-filter
        slot="col_1"
        v-model="filters"
      />
    </index-filter>

    <v-card>
      <v-card-text class="d-flex justify-space-between">
        <div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="tableList.paginate"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>
        </div>

        <div>
          <create-dialog
            v-if="$can('distributor_team:create', 'distributor_team_management')"
            @created="tableList.paginate"
          />
        </div>
      </v-card-text>

      <team-table
        ref="tableRef"
        :table-list="tableList"
      />
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { DistributorIdFilter, IndexFilter, SearchFilter } from '@/components'
import { useTableList } from '@/composables'
import { mdiRefresh } from '@mdi/js'
import CreateDialog from '../components/team/CreateDialog.vue'
import TeamTable from '../components/team/TeamTable.vue'
import { teamService } from '../services'

export default {
  components: {
    IndexFilter,
    SearchFilter,
    DistributorIdFilter,
    TeamTable,
    CreateDialog,
  },
  props: {
  },
  setup(props) {
    const filters = ref({
      search: null,
      distributor_id: null,
    })

    const tableList = useTableList(teamService, filters)

    const {
      tableRef,
      loading,
    } = tableList

    return {
      filters,
      tableRef,
      loading,
      tableList,

      icons: {
        mdiRefresh,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
